













































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Inject } from 'inversify-props'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import type { LojaServiceAdapter, AnalyticsServiceAdapter } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindAnalyticsUseCase, FindUsuarioUseCase } from '@/usecases';
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Usuario } from '@/models';
@Component({
	components: {
		SeletorDeLojas,
	},
})
export default class TelaInicialAnalytics extends Vue {
	carregandoDashboard = false
	erro: null | Error = null
	findAnalyticsUseCase = new FindAnalyticsUseCase()
	dashboardNumber = process.env.VUE_APP_ANALYTICS_DASHBOARD_NUMBER
	dashboardURL = ''
	filtroDeLojas = []
	usuario: Usuario | null = null

	@Inject('LojaServiceAdapter')
	lojaService!: LojaServiceAdapter

	@Inject('AnalyticsServiceAdapter')
	analyticsService!: AnalyticsServiceAdapter

	async created() {
		try {
			if (!UserLoginStore.usuario) throw new Error('Nenhum usuário logado')
			this.usuario = (await new FindUsuarioUseCase().findById(UserLoginStore.usuario?.id))
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	formataNomeDaLojaChip(nome: string) {
		const nomeSplitado = nome.match(/[a-zA-Z]{4,}/g)
		if (!nomeSplitado) return nome
		return nomeSplitado.map(nome => nome.substr(0, 4)).join('.')
	}

	remove(item) {
		const index = this.filtroDeLojas.findIndex(loja => loja === item.id);
		if (index !== -1) {
			this.filtroDeLojas.splice(index, 1);
		} else {
			this.filtroDeLojas = [];
		}
	}

	async aplicarFiltro() {
		this.carregandoDashboard = true
		try {
			if (!this.dashboardNumber) {
				throw new Error('Número do dashboard não definido')
			}
			const pathSplitado = this.$route.path.split('/')
			const data = await this.findAnalyticsUseCase.find(pathSplitado[pathSplitado.length-1], this.filtroDeLojas)
			this.dashboardURL = data.url
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.carregandoDashboard = false
		}
	}

	@Watch('$route.path', {deep:true})
	onChange() {
		this.dashboardURL = ''
	}
}
